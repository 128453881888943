import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function UserController() {
    //> checkUser
    this.checkUser = async function (username) {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}User/CheckUser`,
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "username": username
                })
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }

    //> confirmUser
    this.confirmUser = async function (username, verifyCode) {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}User/ConfirmUser`,
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "username": username,
                    "verifyCode": verifyCode,
                })
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }

    //> login
    this.login = async function (username, password) {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}User/Login`,
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "username": username,
                    "password": password,
                })
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }

    //> getProfile
    this.getProfile = async function () {
        let result = { isSuccess: true, message: "Success", value: null };
        const token = getToken();
        try {
            const response = await axios.request({
                method: 'get',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}User/GetProfile`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: null
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }
    //
}