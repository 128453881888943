import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function ServiceController() {
  const token = getToken();

  //> getStates
  this.getStates = async function () {
    let result = { isSuccess: true, message: "Success", value: null };
    try {
      //> for test
      result.value = [
        {
          name: "تهران",
          totals: [
            { label: "شهر ها", total: 4 },
            { label: "اقامتگاه ها", total: 6 },
            { label: "اتاق ها", total: 5 },
          ],
        },
        {
          name: "گیلان",
          totals: [
            { label: "شهر ها", total: 3 },
            { label: "اقامتگاه ها", total: 2 },
            { label: "اتاق ها", total: 8 },
          ],
        },
        {
          name: "فارس",
          totals: [
            { label: "شهر ها", total: 2 },
            { label: "اقامتگاه ها", total: 8 },
            { label: "اتاق ها", total: 8 },
          ],
        },
      ];
      return result;
      //<
      const response = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: null,
      });
      result.value = response.data;
    } catch (error) {
      result.isSuccess = false;
      result.message = error.toString();
      if (error.response && error.response.status === 401)
        result.message = error.response.data;
    }
    return result;
  };

  //> getCities
  this.getCities = async function (stateName) {
    let result = { isSuccess: true, message: "Success", value: null };
    try {
      //> for test
      result.value = [
        {
          name: "شهریار",
          totals: [
            { label: "اقامتگاه ها", total: 6 },
            { label: "اتاق ها", total: 5 },
          ],
        },
        {
          name: "ورامین",
          totals: [
            { label: "اقامتگاه ها", total: 2 },
            { label: "اتاق ها", total: 8 },
          ],
        },
        {
          name: "هشتگرد",
          totals: [
            { label: "اقامتگاه ها", total: 8 },
            { label: "اتاق ها", total: 8 },
          ],
        },
      ];
      return result;
      //<
      const response = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: null,
      });
      result.value = response.data;
    } catch (error) {
      result.isSuccess = false;
      result.message = error.toString();
      if (error.response && error.response.status === 401)
        result.message = error.response.data;
    }
    return result;
  };

  //> getLocations
  this.getLocations = async function (stateName, cityName) {
    let result = { isSuccess: true, message: "Success", value: null };
    try {
      //> for test
      result.value = [
        {
          name: "اقامتگاه 1",
          totals: [{ label: "اتاق ها", total: 5 }],
        },
        {
          name: "اقامتگاه 2",
          totals: [{ label: "اتاق ها", total: 8 }],
        },
        {
          name: "اقامتگاه 3",
          totals: [{ label: "اتاق ها", total: 8 }],
        },
      ];
      return result;
      //<
      const response = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: null,
      });
      result.value = response.data;
    } catch (error) {
      result.isSuccess = false;
      result.message = error.toString();
      if (error.response && error.response.status === 401)
        result.message = error.response.data;
    }
    return result;
  };

  //> getRooms
  this.getRooms = async function (stateName, cityName, locationName) {
    let result = { isSuccess: true, message: "Success", value: null };
    try {
      //> for test
      result.value = [
        {
          roomId: "1",
          name: "آپارتمان یک خوابه",
          imageUrl: "/images/a1.jpg",
        },
        {
          roomId: "2",
          name: "آپارتمان دو خوابه",
          imageUrl: "/images/a2.jpg",
        },
        {
          roomId: "3",
          name: "ویلا",
          imageUrl: "/images/v1.jpg",
        },
        {
          roomId: "4",
          name: "ویلای ویژه",
          imageUrl: "/images/vip.jpg",
        },
      ];
      return result;
      //<
      const response = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: null,
      });
      result.value = response.data;
    } catch (error) {
      result.isSuccess = false;
      result.message = error.toString();
      if (error.response && error.response.status === 401)
        result.message = error.response.data;
    }
    return result;
  };

  //> getRoomDetails
  this.getRoomDetails = async function (roomId) {
    let result = { isSuccess: true, message: "Success", value: null };
    try {
      //> for test
      result.value = {
        images: [
          { title: "تصویر 1", imageUrl: "/images/g1.jpg", smallImageUrl: "/images/g1.jpg" },
          { title: "تصویر 2", imageUrl: "/images/g2.jpg", smallImageUrl: "/images/g2.jpg" },
          { title: "تصویر 3", imageUrl: "/images/g3.jpg", smallImageUrl: "/images/g3.jpg" },
          { title: "تصویر 4", imageUrl: "/images/g4.jpg", smallImageUrl: "/images/g4.jpg" },
          { title: "تصویر 5", imageUrl: "/images/g5.jpg", smallImageUrl: "/images/g5.jpg" },
        ],
        state: "تهران",
        name: "ویلایی دو خوابه",
        city: "شهریار",
        location: "اقامتگاه 1",
        address: "آدرس ویلایی",
        latitude: 35.7665394, 
        longitude: 51.4749824
      };
      return result;
      //<
      const response = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: null,
      });
      result.value = response.data;
    } catch (error) {
      result.isSuccess = false;
      result.message = error.toString();
      if (error.response && error.response.status === 401)
        result.message = error.response.data;
    }
    return result;
  };
  //>
}
