import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
//
import { BrowserRouter, Route, Routes } from "react-router-dom";
//
import AboutUs from "./views/about-us.js";
import ContactUs from "./views/contact-us.js";
//
import { getToken, setToken } from "./utils/utility";
import Login from "./views/login.js";
import Home from "./views/home.js";
import { PanelMenu } from "primereact/panelmenu";
import { PrimeIcons } from "primereact/api";
import AddNewQueue from "./views/add-new-queue.js";

function App() {
  const token = getToken();

  const menuItems = [
    { label: "خانه", icon: PrimeIcons.HOME, linkUrl: "#" },
    { label: "رزرو جدید", icon: PrimeIcons.PLUS, linkUrl: "/#/add-new-queue" },
    { label: "رزرو های من", icon: PrimeIcons.LIST , linkUrl: "#"},
    { label: "پرداخت ها", icon: PrimeIcons.MONEY_BILL , linkUrl: "#"},
    { label: "نشان شده ها", icon: PrimeIcons.BOOKMARK , linkUrl: "#"},
    { label: "پیام ها", icon: PrimeIcons.COMMENTS , linkUrl: "#"},
    { label: "پروفایل", icon: PrimeIcons.USER , linkUrl: "#"},
    { label: "خروج", icon: PrimeIcons.SIGN_OUT , linkUrl: "#"},
  ];

  if (!token) {
    return (
      <Routes>
        <Route path="/" Component={Login} />
      </Routes>
    );
  }

  return (
    <div className="main">
      <header className="head"></header>
      <div className="content">
        <aside>
          <ul>
            {menuItems.map((item) => (
              <li>
                <a href={item.linkUrl}>
                  <i className={item.icon}></i>
                  <span>{item.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </aside>
        <div>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/add-new-queue" Component={AddNewQueue} />
            <Route path="/about-us" Component={AboutUs} />
            <Route path="/contact-us" Component={ContactUs} />
          </Routes>
        </div>
      </div>
      <footer>
        <p>© کلیه حقوق محفوظ و متعلق به شرکت شبکه سفید می باشد.</p>
      </footer>
    </div>
  );
}

export default App;
