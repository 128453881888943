//export const API_BASE_URL = "http://localhost:8005/";
export const API_BASE_URL = "https://rs-api.offplus.ir/";

export function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

export function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
}

export function saveCustomerPackageId(customerPackageId) {
    sessionStorage.setItem('customerPackageId', customerPackageId);
}

export function loadCustomerPackageId() {
    return  sessionStorage.getItem('customerPackageId');
}

const listeners = {};

export function subscribe(event, callback) {
  if (!listeners[event]) {
    listeners[event] = [];
  }
  listeners[event].push(callback);
}

export function emit(event, data) {
  if (listeners[event]) {
    listeners[event].forEach(callback => callback(data));
  }
}