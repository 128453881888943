import { getToken } from "../utils/utility";

export default function Home() {
  const token = getToken();

  if (!token) return <h1>Access is denied!</h1>;
  else
    return (
   <h1>Home page</h1>
    );
}
