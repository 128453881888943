import "../styles/add-new-queue.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import ServiceController from "../services/service-controller";
import { PrimeIcons } from "primereact/api";
import { Galleria } from "primereact/galleria";
import { Map } from "@neshan-maps-platform/ol";
import NeshanMap from "@neshan-maps-platform/react-openlayers";
import { Button } from "primereact/button";

export default function AddNewQueue() {
  const { t } = useTranslation();
  const localizer = t;
  const toast = useRef(null);
  const stepperRef = useRef(null);
  const serviceController = new ServiceController();
  const mapRef = useRef(null);
  //> Properties
  const [levelType, setLevelType] = useState("states");
  const [currentStateName, setCurrentStateName] = useState(null);
  const [currentCityName, setCurrentCityName] = useState(null);
  const [currentLocationName, setCurrentLocationName] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [pageType, setPageType] = useState("list");
  const [roomDetails, setRoomDetails] = useState({});
  const [startDay, setStartDay] = useState(-1);
  const [endDay, setEndDay] = useState(-1);
  const [hoverDay, setHoverDay] = useState(-1);

  //> Commpon Properties
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  let rowNumber = 0;
  //> weeks
  const weeks = [
    [0, 0, 0, 0, 1, 2, 3],
    [4, 5, 6, 7, 8, 9, 10],
    [11, 12, 13, 14, 15, 16, 17],
    [18, 19, 20, 21, 22, 23, 24],
    [25, 26, 27, 28, 29, 30, 31],
  ];

  //> totalServices
  const totalServices = [
    { rowNumber: 1, name: "صبحانه", price: 2000000, total: 0 },
    { rowNumber: 2, name: "ناهار", price: 3000000, total: 0 },
    { rowNumber: 3, name: "شام", price: 3500000, total: 0 },
  ];

  //> durationServices
  const durationServices = [
    { rowNumber: 1, name: "مدت اقامت", price: 2000000, total: 0 },
  ];

  //> properties
  const properties = [
    { name: "تعداد نفرات", value: "" },
    { name: "تعداد کودک", value: "" },
  ];

  //> getData
  const getData = async function () {
    const stateResult = await serviceController.getStates();
    setStates(stateResult.value);
  };

  //> selectState
  const selectState = async function (stateName) {
    const citiesResult = await serviceController.getCities(stateName);
    setCities(citiesResult.value);
    setLevelType("cities");
    setCurrentStateName(stateName);
  };

  //> selectCity
  const selectCity = async function (cityName) {
    const locationsResult = await serviceController.getLocations(
      currentStateName,
      cityName
    );
    setLocations(locationsResult.value);
    setLevelType("locations");
    setCurrentCityName(cityName);
  };

  //> selectLocation
  const selectLocation = async function (locationName) {
    setCurrentLocationName(locationName);
    stepperRef.current.nextCallback();
    const roomsResult = await serviceController.getRooms(
      currentStateName,
      currentCityName,
      locationName
    );
    setRooms(roomsResult.value);
  };

  //> selectRoom
  const selectRoom = async function (roomId) {
    setPageType("details");
    const roomDetailsResult = await serviceController.getRoomDetails(roomId);
    setRoomDetails(roomDetailsResult.value);
    //window.myOpenLayer = mapRef ;
    //TODO : must be implement later
    //mapRef.current?.map.getView().setCenter([roomDetailsResult.value.longitude ,roomDetailsResult.value.latitude ]);
  };

  //> responsiveOptions
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  //> Gallery itemTemplate
  const itemTemplate = (item) => {
    return (
      <img src={item.imageUrl} alt={item.title} style={{ height: "400px" }} />
    );
  };

  //> Gallery thumbnailTemplate
  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.smallImageUrl}
        alt={item.title}
        style={{ width: "128px" }}
      />
    );
  };

  //> cancelRoom
  const cancelRoom = function () {
    setPageType("list");
  };

  //> confirmRoom
  const confirmRoom = function () {
    setPageType("list");
    stepperRef.current.nextCallback();
  };

  //> hoverDate
  const hoverDate = function (day) {
    setHoverDay(day);
  };

  //> confirmDate
  const confirmDate = function (day) {
    if (startDay <= 0) {
      setStartDay(day);
      return;
    }

    if (day <= startDay) return;

    setEndDay(day);
    durationServices[0].total = endDay - startDay;
    stepperRef.current.nextCallback();
  };

  //> confirmServices
  const confirmServices = function () {
    stepperRef.current.nextCallback();
  };

  //> confirmProperties
  const confirmProperties = function () {
    stepperRef.current.nextCallback();
  };

  //> useEffect
  useEffect(() => {
    getData();
  }, []);

  //>
  return (
    <>
      <Toast ref={toast} />
      <article className="add-new-queue">
        <header>
          <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
            <StepperPanel header="محل اقامت">
              <h2>
                {levelType === "cities" && (
                  <>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setLevelType("states")}
                    >
                      استان ها
                    </a>
                    <i className={PrimeIcons.ANGLE_LEFT}></i>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setLevelType("cities")}
                    >
                      {currentStateName}
                    </a>
                    <i className={PrimeIcons.ANGLE_LEFT}></i>
                  </>
                )}
                {levelType === "locations" && (
                  <>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setLevelType("states")}
                    >
                      استان ها
                    </a>
                    <i className={PrimeIcons.ANGLE_LEFT}></i>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setLevelType("cities")}
                    >
                      {currentStateName}
                    </a>
                    <i className={PrimeIcons.ANGLE_LEFT}></i>
                    <a>{currentCityName}</a>
                    <i className={PrimeIcons.ANGLE_LEFT}></i>
                  </>
                )}
                {levelType === "states" && <span>انتخاب استان محل اقامت</span>}
                {levelType === "cities" && <span>انتخاب شهر محل اقامت</span>}
                {levelType === "locations" && <span>انتخاب اقامتگاه</span>}
              </h2>

              {levelType === "states" && (
                <ul className="locations" hidden={levelType === "states"}>
                  {states.map((item) => (
                    <li>
                      <a onClick={() => selectState(item.name)}>
                        <h3>{item.name}</h3>
                        {item.totals.map((sItem) => (
                          <div>
                            <label>{sItem.label}</label>
                            <strong>{sItem.total}</strong>
                          </div>
                        ))}
                      </a>
                    </li>
                  ))}
                </ul>
              )}

              {levelType === "cities" && (
                <ul className="locations">
                  {cities.map((item) => (
                    <li>
                      <a onClick={() => selectCity(item.name)}>
                        <h3>{item.name}</h3>
                        {item.totals.map((sItem) => (
                          <div>
                            <label>{sItem.label}</label>
                            <strong>{sItem.total}</strong>
                          </div>
                        ))}
                      </a>
                    </li>
                  ))}
                </ul>
              )}

              {levelType === "locations" && (
                <ul className="locations">
                  {locations.map((item) => (
                    <li>
                      <a onClick={() => selectLocation(item.name)}>
                        <h3>{item.name}</h3>
                        {item.totals.map((sItem) => (
                          <div>
                            <label>{sItem.label}</label>
                            <strong>{sItem.total}</strong>
                          </div>
                        ))}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </StepperPanel>
            <StepperPanel header="اقامتگاه">
              <h2>انتخاب اقامتگاه در شهر انتخاب شده</h2>
              {pageType === "list" && (
                <ul className="rooms">
                  {rooms.map((item) => (
                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={(item) => selectRoom(item.roomId)}
                      >
                        <img src={item.imageUrl} />
                        <span>{item.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}

              {pageType === "details" && (
                <>
                  <ol className="details">
                    <li>
                      <label>نام</label>
                      <span>{roomDetails.name}</span>
                    </li>
                    <li>
                      <label>نام استان</label>
                      <span>{roomDetails.state}</span>
                    </li>
                    <li>
                      <label>نام شهر</label>
                      <span>{roomDetails.city}</span>
                    </li>
                    <li>
                      <label>نام اقامتگاه</label>
                      <span>{roomDetails.location}</span>
                    </li>
                    <li>
                      <label>آدرس</label>
                      <span>{roomDetails.address}</span>
                    </li>
                  </ol>

                  <div className="buttons">
                    <Button onClick={(e) => confirmRoom()}>انتخاب اتاق</Button>
                    <Button onClick={(e) => cancelRoom()}>انصراف</Button>
                  </div>

                  <Galleria
                    value={roomDetails.images}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    style={{ maxWidth: "100%" }}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                    showItemNavigators
                  />

                  <NeshanMap
                    mapKey="web.1c8021a2962c46be925658f642c30c83"
                    ref={mapRef}
                    center={{
                      latitude: 35.7665394,
                      longitude: 51.4749824,
                    }}
                    zoom={14}
                    style={{ width: "100%", height: 400 }}
                  ></NeshanMap>
                </>
              )}
            </StepperPanel>
            <StepperPanel header="تاریخ">
              <h2>انتخاب مدت اقامت : مشاهده و انتخاب از بین زمانهای خالی</h2>

              <table className="calendar">
                <thead>
                  <tr>
                    <th>شنبه</th>
                    <th>یکشنبه</th>
                    <th>دوشنبه</th>
                    <th>سه شنبه</th>
                    <th>چهار شنبه</th>
                    <th>پنج شنبه</th>
                    <th>جمعه</th>
                  </tr>
                </thead>
                <tbody>
                  {weeks.map((item) => (
                    <tr>
                      {item.map((dayItem) => (
                        <td>
                          <a
                            onClick={() => confirmDate(dayItem)}
                            onMouseEnter={() => setHoverDay(dayItem)}
                            onMouseLeave={() => setHoverDay(-1)}
                            className={
                              dayItem === startDay
                                ? "active"
                                : (startDay > 0 && hoverDay > 0 && dayItem > startDay && hoverDay > startDay &&  hoverDay > dayItem )
                                ? "hover"
                                : ""
                            }
                          >
                            {dayItem > 0 ? dayItem : ""}
                          </a>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </StepperPanel>
            <StepperPanel header="خدمات">
              <h2>
                انتخاب سایر خدمات تعدادی : محاسبه و پیش نمایش قیمت و مبلغ بیعانه
              </h2>
              <div className="services">
                <table>
                  <thead>
                    <tr>
                      <th>ردیف</th>
                      <th>شرح خدمات</th>
                      <th>قیمت</th>
                      <th>تعداد</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalServices.map((item) => (
                      <tr>
                        <td>{item.rowNumber}</td>
                        <td>{item.name}</td>
                        <td>{item.price.toLocaleString("en-us")}</td>
                        <td>
                          <input
                            type="number"
                            value={item.total}
                            onChange={(e) => (item.total = e.target.value)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table>
                  <thead>
                    <tr>
                      <th>ردیف</th>
                      <th>شرح خدمات</th>
                      <th>قیمت</th>
                      <th>مدت</th>
                    </tr>
                  </thead>
                  <tbody>
                    {durationServices.map((item) => (
                      <tr>
                        <td>{item.rowNumber}</td>
                        <td>{item.name}</td>
                        <td>{item.price.toLocaleString("en-us")}</td>
                        <td>
                          <input
                            type="number"
                            value={item.total}
                            onChange={(e) => (item.total = e.target.value)}
                          />
                          روز
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="buttons">
                <Button onClick={(e) => confirmServices()}>
                  تایید خدمات و ادامه
                </Button>
              </div>
            </StepperPanel>
            <StepperPanel header="ثبت مشخصات">
              <h2>ثبت مشخصات فرد و خصوصیات مربوط به رزرو</h2>

              <div className="properties">
                {properties.map((item) => (
                  <div>
                    <label>{item.name} : </label>
                    <input type="text" value={item.value} />
                  </div>
                ))}
              </div>

              <div className="buttons">
                <Button onClick={(e) => confirmProperties()}>
                  تایید ادامه
                </Button>
              </div>
            </StepperPanel>
            <StepperPanel header="پرداخت بیعانه">
              <h2>نمایش پیش فاکتور جهت پرداخت بیعانه</h2>
              <table className="summary">
                <thead>
                  <tr>
                    <th>ردیف</th>
                    <th>شرح</th>
                    <th>قیمت</th>
                    <th>تعداد / مدت</th>
                    <th>قیمت کل</th>
                  </tr>
                </thead>
                <tbody>
                  {totalServices.map((item) => (
                    <tr>
                      <td>{++rowNumber}</td>
                      <td>{item.name}</td>
                      <td>{item.price.toLocaleString("en-us")}</td>
                      <td>{item.total}</td>
                      <td>
                        {(item.total * item.price).toLocaleString("en-us")}
                      </td>
                    </tr>
                  ))}
                  {durationServices.map((item) => (
                    <tr>
                      <td>{++rowNumber}</td>
                      <td>{item.name}</td>
                      <td>{item.price.toLocaleString("en-us")}</td>
                      <td>{item.total}</td>
                      <td>
                        {(item.total * item.price).toLocaleString("en-us")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="buttons">
                <Button onClick={(e) => confirmProperties()}>
                  تایید پیش فاکتور و پرداخت نهایی
                </Button>
              </div>
            </StepperPanel>
          </Stepper>
        </header>
      </article>
    </>
  );
}
