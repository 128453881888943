import "../styles/login.css";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import { InputOtp } from "primereact/inputotp";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL, getToken, setToken } from "../utils/utility";

export default function Login(props) {
  const { t } = useTranslation();
  const localizer = t;
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyCodeSent, setIsVerifyCodeSent] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [canSendAgain, setCanSendAgain] = useState(false);
  const userController = new UserController();
  const navigate = useNavigate();

  const login = async function () {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const result = await userController.login(username, password);
      if (result.isSuccess === false) throw result.message;
      setToken(result.value);
      //navigate("/home", { replace: true });
      window.location.reload();
    } catch (error) {
      setErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  const send = async function () {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const result = await userController.checkUser(username);
      if (result.isSuccess === false) throw result.message;

      setIsVerifyCodeSent(true);
      setCanSendAgain(false);
      startTimer(60);
    } catch (error) {
      setErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  const startTimer = function () {
    window.waitForResend = 60;
    if (window.timerInterval) clearInterval(window.timerInterval);
    window.timerInterval = setInterval(() => {
      window.waitForResend--;
      document.querySelector("button.send-again > b").innerHTML =
        window.waitForResend.toString();
      if (window.waitForResend <= 0) {
        document.querySelector("button.send-again > b").innerHTML = "";
        clearInterval(window.timerInterval);
        setCanSendAgain(true);
      }
    }, 1000);
  };

  const confirm = async function (verifyCode) {
    try {
      if (verifyCode.length < 4) return;
      setIsLoading(true);
      setErrorMessage("");
      setVerifyCode(verifyCode);
      const result = await userController.confirmUser(username, verifyCode);
      if (result.isSuccess === false) throw result.message;
      setToken(result.value);
      window.location.reload();
      //navigate("/home", { replace: true });
    } catch (error) {
      setErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  const editUsername = function () {
    setIsVerifyCodeSent(false);
  };

  return (
    <article className="login">
      <div className="header">
      </div>
      <h2>{localizer("Login in portal")}</h2>

      {!isVerifyCodeSent && (
        <div>
          <div>
            <label htmlFor="username">
              {localizer("Mobile number")}
            </label>
            <InputText
              id="username"
              className="w-full"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="password">{localizer("Password")}</label>
            <Password
              id="password"
              className="w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              feedback={false}
              toggleMask
            />
          </div>

          <Button
            className="w-full p-success"
            label={localizer("Login with password")}
            onClick={() => login()}
            severity="success"
            loading={isLoading}
          />

          <div className="or-otp">
            <span>{localizer("or")}</span>
          </div>

          <Button
            className="w-full mt-2"
            label={localizer("Send otp code")}
            onClick={() => send()}
            loading={isLoading}
          />
        </div>
      )}

      {isVerifyCodeSent && (
        <>
          <div>{localizer("Enter verify code sent to ")}</div>
          <div>
            <b className="site-ltr">{username}</b>
          </div>
          <div className="flex">
            <InputOtp
              value={verifyCode}
              onChange={(e) => confirm(e.value)}
              integerOnly
            />
          </div>

          <button
            className="p-button p-component p-button-text send-again"
            onClick={() => send()}
            disabled={!canSendAgain}
          >
            <span className="p-button-label p-c">
              {localizer("Send again")}
            </span>
            <b></b>
          </button>
          <Button
            label={localizer("Edit mobile number")}
            icon="pi pi-file-edit"
            onClick={() => editUsername()}
            text
          />
        </>
      )}

      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </article>
  );
}
